.user-profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.profile-header img {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  margin: 15px;
}

.profile-header-title h2 {
  margin: 0;
  padding: 0;
}

.profile-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.profile-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #424242;
  border-radius: 6px;
  background-color: #1d1d1d;
  color: #e0e0e0;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.profile-edit:hover {
  background-color: #333333;
}

.profile-role {
  display: flex;
  align-items: center;
  padding: 9px 15px;
  border: 1px solid #424242;
  border-radius: 4px;
  background-color: #282c34;
  color: #e0e0e0;
  font-size: 10px;
  margin-bottom: 10px;
}

.divider {
  width: 100%;
  border: 0;
  height: 1px;
  background: #424242;
  margin: 20px 0 10px;
}

.profile-trophies {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.trophies-list {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.trophies-list img {
  width: 10%;
  height: 10%;
  border-radius: 100px;
  margin: 5px 15px 20px 0;
  cursor: pointer;
}
