.more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
}
