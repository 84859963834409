.asset-selector {
  position: absolute;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
  max-height: 200px;
  width: 30%;
  min-width: 320px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.asset-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  cursor: default;
}

.asset-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  transition: background-color 0.2s;
}

.asset-option:hover {
  background-color: #3a3a3a;
}

.asset-preview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.asset-name {
  font-size: 14px;
  color: grey;
}

.asset-close {
  cursor: pointer;
}

.asset-close:hover {
  color: #fff;
}
