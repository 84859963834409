.search-result-container {
  display: grid;
  align-self: center;
  margin-top: 20px;
}

.search-item {
  flex: 0 0 auto;
  position: relative;
  margin: 5px;
  overflow: hidden;
  border-radius: 10px;
  height: 165px;
  width: 300px;
}

.search-result-empty {
  padding: 10px;
  text-align: center;
  color: #999;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #212121;
  color: grey;
  border: 1px solid #2a2a2a;
  border-radius: 5px;
}

.pagination button:hover {
  background-color: #2a2a2a;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .search-result-container {
    grid-template-columns: auto;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .search-result-container {
    grid-template-columns: auto auto;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .search-result-container {
    grid-template-columns: auto auto;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1200px) and (orientation: landscape) {
  .search-result-container {
    grid-template-columns: auto auto auto;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .search-result-container {
    grid-template-columns: auto auto auto;
  }
}
