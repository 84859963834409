.gallery-container {
  display: grid;
  align-self: center;
  margin-top: 20px;
}

.gallery-item {
  flex: 0 0 auto;
  position: relative;
  margin: 5px;
  overflow: hidden;
  border-radius: 10px;
  height: 165px;
  width: 300px;
}

.column {
  float: left;
  width: 25%;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  position: relative;
  margin: auto;
  width: 90%;
  padding: 0;
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {
    opacity: 0.35;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 1s;
}

.modal .hidden {
  display: none;
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  width: 100%;
  max-width: 1024px;
}

.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.mySlides img {
  width: 100%;
  max-width: 960px;
}

.mySlides.active {
  display: block;
}

.mySlides .img-container {
  display: flex;
  justify-content: center;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 55%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.next {
  right: -50px;
}

.prev {
  left: -50px;
}

.prev:hover,
.prev:focus,
.next:hover,
.next:focus {
  color: #999;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  font-weight: bold;
  position: absolute;
  top: -40px;
  left: 25px;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .gallery-container {
    grid-template-columns: auto;
  }

  .mySlides img {
    height: 200px;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .gallery-container {
    grid-template-columns: auto auto;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .gallery-container {
    grid-template-columns: auto auto;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .gallery-container {
    grid-template-columns: auto auto;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .gallery-container {
    grid-template-columns: auto auto;
  }
}
