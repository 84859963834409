.thumbnail-card-container {
  margin-bottom: 5px;
}

.thumbnail-card-container img {
  width: 100%;
  cursor: pointer;
}

.thumbnail-card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.thumbnail-card-container:hover .thumbnail-card-overlay {
  opacity: 1;
}

.thumbnail-card-overlay h3 {
  margin: 0;
  font-size: 10px;
}

.thumbnail-card-overlay p {
  margin: 0;
  font-size: 8px;
}
