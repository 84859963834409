.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.search-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  gap: 20px;
}

.filters {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filters label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: black;
}

.filters input {
  margin-right: 5px;
}

.filter-icon,
.sort-icon {
  color: #3a3a3a;
  cursor: pointer;
}

.filter-icon:hover,
.sort-icon:hover {
  color: grey;
}

.sort {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.sort label {
  font-size: 14px;
}

.sort select {
  padding: 2px 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.filter-sort-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 60px;
  right: 0px;
  width: 300px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}

.popup .sort {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popup .filters {
  margin-bottom: 20px;
}

.popup .filters label {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.popup .filters input {
  margin-right: 5px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.clear-button {
  padding: 10px 20px;
  border: none;
  background-color: #ff4d4d;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.apply-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
  .filters label,
  .sort label {
    font-size: 12px;
  }

  .sort select {
    font-size: 12px;
  }
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
  .filters label,
  .sort label {
    font-size: 13px;
  }

  .sort select {
    font-size: 13px;
  }
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
  .search-input-container {
    max-width: 1024px;
  }
}
