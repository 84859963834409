.spatial-video-player {
    max-width: '800px';
    margin: '0 auto';
}

.spatial-video-list {
    width: 100%;
    padding: 32px;
}

.spatial-video-card {
    width: 100%;
    margin: 8px;
    margin-top: 32px;
    display: inline-block;
    vertical-align: top;
}

.spatial-video-card video {
    width: 100%;
}

.spatial-card-header {
    display: flex;
    gap: 16px;
}

.spatial-card-header h3 {
    flex-direction: row;
    flex-basis: 70%;
}

.dolby-icons {
    flex-direction: row;
    flex-basis: 30%;
}

.dolby-icons img {
    width: 64px;
    height: auto;
    margin-top: 12px;
    margin-right: 16px;
}

@media(min-width: 1000px) {
    .spatial-video-card {
        width: 47%;
        
    }
}