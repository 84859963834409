.fullscreen-container {
  position: fixed;
  z-index: 1;
  padding-top: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #1b1b1b;
  pointer-events: auto;
}

.fullscreen-container img,
.fullscreen-container iframe,
.fullscreen-container div,
.fullscreen-container a {
  pointer-events: none;
}

.fullscreen-container h1 {
  text-align: center;
}
