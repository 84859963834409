.right-side-nav {
  width: 325px;
  background-color: #282c34;
  background-color: rgba(0, 0, 0, 1.1);
  color: white;
  padding: 0 30px 30px 30px;
  position: fixed;
  height: 100%;
  top: 0;
  right: -100%;
  overflow-y: scroll;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  border-radius: 15px 0 0 15px;
}

.right-side-nav.open {
  right: 0;
}

.right-nav-close,
.right-nav-toggle {
  border: 1px solid #2a2a2a;
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 5px;
}

.right-nav-close {
  font-size: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  margin: 10px 5px 0 0;
}

.right-nav-toggle {
  display: block;
  font-size: 26px;
  cursor: pointer;
  top: 20px;
  right: 20px;
}

.right-nav-toggle:hover,
.rightnav-close:hover {
  background-color: #3a3a3a;
}

.right-side-nav .right-nav-content {
  margin-top: 100px;
}

.right-side-nav ul {
  list-style: none;
  padding: 0;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.right-side-nav ul li {
  font-size: 14px;
  margin-bottom: 15px;
  cursor: pointer;
}

.right-side-nav ul li:last-child {
  margin-bottom: 0;
}

.right-side-nav ul li:hover {
  color: fuchsia;
}

.right-side-nav ul li::after {
  content: none;
}

.right-side-nav ul header {
  margin-bottom: 10px;
  font-size: large;
  font-weight: 700;
}

.divider {
  width: 100%;
  border: 0;
  height: 1px;
  background: #424242;
  margin: 20px 0 10px;
}

.nav-user-profile {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.nav-user-profile img {
  max-width: 40px;
  border-radius: 19px;
  margin: 10px;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .right-side-nav {
    width: 100%;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .right-side-nav {
    width: 100%;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
}

/* Desktops */
@media only screen and (min-width: 1285px) {
}
