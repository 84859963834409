.arrow-indicator::after {
  content: ">>";
  margin-left: 5px;
  font-weight: 600;
}

.component-container {
  padding-top: 15px;
}

/* Prevent text selection on elements */
.no-select {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
  body {
    font-size: smaller;
  }

  .component-container {
    padding-bottom: 10px;
  }
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
  body {
    font-size: small;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .component-container {
    max-width: 1024px; 
    width: 100%;
    margin: 0 auto;
  }
}
