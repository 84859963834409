.video-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

.one-video {

}

.two-video {

}

.four-video {
    
}

