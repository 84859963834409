.form-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
  .form-container{
    height: 100vh;
  }
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
  
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
  
}