.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #2a2a2a;
  background-color: #212121;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
}
