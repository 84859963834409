.app-container {
  color: white;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  overflow: hidden;
}

@font-face {
  font-family: "NeogreyRegular";
  src: url("../assets/fonts/NeogreyRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Helvetica", sans-serif;
}

.full-screen-mode {
  overflow: auto;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .app-container {
    padding: 15px 20px;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .app-container {
    padding: 30px 40px;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .app-container {
    padding: 30px 50px;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .app-container {
    padding: 40px 104px;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .app-container {
    padding: 40px 32px;
    max-width: 1088px; 
    /* 1088 = 1024 + 32 * 2 (1024 content) */
    /* max-width: 1264px;  */
    /* 1264 = 1200 + 32 * 2 (1200 content) */
    margin: 0 auto;
  }
}
