.footer {
  text-align: center;
  font-weight: 550;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .footer {
    font-size: x-small;
  }
  .footer-container {
    padding-top: 15px; 
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .footer {
    font-size: x-small;
  }
  .footer-container {
    padding-top: 20px; 
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .footer {
    font-size: small;
  }
  .footer-container {
    padding-top: 20px; 
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .footer {
    font-size: small;
  }
  .footer-container {
    padding-top: 25px; 
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .footer-container {
    padding-top: 30px;
  }
}
