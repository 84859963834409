.field {
  position: relative;
  width: 80%;
  min-width: 180px;
  height: 30px;
  margin-top: 5px;
}

.field input {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 400;
}

.field input {
  border: 1px solid #ccc;
  padding: 0 15px;
}

.search-icon {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: grey;
  cursor: pointer;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
  .field {
    font-size: x-small;
  }
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
  .field {
    font-size: small;
  }
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
  .field {
  }
}
