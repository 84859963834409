.superuser-container {
  display: flex;
  position: relative;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 0;
  transition: background-color 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }
}
