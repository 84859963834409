* {
  box-sizing: border-box;
}

.login-form {
  position: relative;
  max-width: 430px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #2a2a2a;
  margin: auto;
}

.login-form header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

form .field {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}

.field input,
.field button {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
}

.field input {
  background-color: #212121;
  color: #fff;
  outline: none;
  padding: 0 15px;
}

.field input:not(:focus) {
  border: 1px solid #2a2a2a;
}

.checkbox-field {
  position: relative;
  width: 100%;
  height: 10px;
  margin-top: 20px;
  font-size: smaller;
}

.checkbox-field input {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  margin-top: 0.5rem;
  background-color: #2a2a2a;
  border: 1px solid #2a2a2a;
}

.terms-link span,
.terms-link a {
  text-align: center;
  color: grey;
}

.terms-link a:hover {
  color: #007bff;
  text-decoration: underline;
}

.checkbox-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-field input[type="checkbox"] {
  width: auto;
  margin: 0;
  border: 1px solid #2a2a2a;
  cursor: pointer;
}

.field button {
  padding: 10px;
  background-color: #2a2a2a;
  border: 1px solid #2a2a2a;
  color: white;
  cursor: pointer;
}

.field button:hover {
  background-color: #3a3a3a;
}

.form-link {
  text-align: center;
  margin-top: 10px;
  color: grey;
}

.form-link span,
.form-link span a,
.form-link button {
  font-size: 14px;
  font-weight: 400;
}

.form-link a,
.form-link button {
  color: #007bff;
}

.form-link a:hover,
.form-link button:hover {
  text-decoration: underline;
}

.form-link button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.eye-icon {
  position: absolute;
  font-size: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: grey;
  cursor: pointer;
}

.default-eye-icon {
  right: 10px;
}

.move-eye-icon {
  right: 35px;
}

.valid-icon,
.invalid-icon {
  position: absolute;
  font-size: 18px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.valid-icon {
  color: green;
  margin-left: 8px;
}

.invalid-icon {
  color: red;
  margin-left: 8px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.instructions {
  color: grey;
  font-size: 14px;
  font-weight: 400;
}

.recaptcha-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.auth-message {
  color: grey;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
}
