.video-embed {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.video-embed iframe {
  max-width: 100%;
  border: none;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
  .video-embed iframe {
    max-width: 80%;
    height: 240px;
  }
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
  .video-embed iframe {
    max-width: 90%;
    height: 300px;
  }
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
}