.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-container h1 {
  font-weight: 600; 
  margin-bottom: 5px;
}

.content-container footer {
  color: gray;
  margin-top: 5px;
}

.content-image footer {
  color: gray;
  font-size: x-small;
  width: 90%;
  margin-top: 10px;
}

.content-text {
  width: 90%;
  margin-top: 30px;
}

.content-image {
  margin-top: 15px;
  width: 100%;
}

.content-image img {
  width: 100%;
  height: 90%;
}

.author {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.author img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 5px;
}

.video-selector {
  text-align: right;
  margin-top: 32px;
}

.video-selector span {
  margin-right: 8px;
}

.video-selector select {
  height: 44px;
  min-width: 180px;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .content-image {
    height: 200px;
  }
  .content-container {
    padding: 0 40px;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .content-image {
    height: 200px;
  }
  .content-container {
    padding: 0 60px;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .content-image {
    height: 250px;
  }
  .content-container {
    padding: 0 70px;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .content-image {
    height: 250px;
  }
  .content-container {
    padding: 0 32px;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .content-image {
    height: 350px;
  }
  .content-container {
    padding: 0 64px;
  }
}
