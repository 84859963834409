.hero-img-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-img {
  max-width: 100%;
  display: block;
}

.hero-footnote {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 550;
}

.hero-footnote-title {
  margin-left: 5px;
}

/* Smartphones (below 768px) */
@media (max-width: 767px) {
  .hero-img {
    max-width: 100%;
  }

  .hero-footnote {
    font-size: x-small;
  }
}

/* Tablets (768px - 959px) */
@media (min-width: 768px) and (max-width: 959px) {
  .hero-img {
    max-width: 100%;
  }

  .hero-footnote {
    font-size: small;
  }
}

/* Desktops (960px and above) */
@media (min-width: 960px) {
}