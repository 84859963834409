.editor {
  border-radius: 5px;
  background-color: #212121;
  border: 1px solid #2a2a2a;
  padding: 0 15px;
  margin-bottom: 15px;
}

.editor:focus {
  outline: none;
  border: 1px solid #fff;
}

.editor p,
span {
  font-weight: 450;
}

.editor span {
  padding: 15px 0;
}
