.slider-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin-bottom: 25px;
}

.slider-content {
  position: relative;
}

.slider-item {
  flex: 0 0 auto;
  position: relative;
  scroll-snap-align: start;
  overflow: hidden;
  margin-left: 29px;
}

.more-thumbnail {
  background-color: #4a4a4a;
  text-align: center;
  color: #fff;
  aspect-ratio: 320 / 180;
  min-width: 320px;
}

.more-thumbnail h4 {
  padding: 0;
  margin: 0;
  color: grey;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.more-thumbnail:hover {
  background-color: #2a2a2a;
  cursor: pointer;
}

.slider-header {
  font-weight: 600;
}

.default-thumbnail {
  background-color: #4a4a4a;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  aspect-ratio: 320 / 180;
  min-width: 320px;
}

.slider-content .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 0;
  margin-top: 0;
  color: lightgrey;
  opacity: 0.25;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  right: -15px;
  scale: 4;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .slider-container {
    padding-top: 5px;
    margin-bottom: 0;
  }
  .slider-header {
    font-size: xx-small;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .slider-container {
    padding-top: 5px;
    margin-bottom: 0;
  }
  .slider-header {
    font-size: xx-small;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .slider-container {
    padding-top: 10px;
  }
  .slider-header {
    font-size: smaller;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .slider-container {
    padding-top: 10px;
  }
  .slider-header {
    font-size: smaller;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .slider-container {
    padding-top: 15px;
  }
}
