ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 14px;
}

ul li span {
  display: inline-block;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

ul li:last-child {
  margin-right: 0;
}

.nav-bar ul li:not(:last-child):after {
  display: inline-block;
  content: ">>";
  font-weight: bold;
  font-size: 0.9em;;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

ul li:hover span {
  color: fuchsia;
  scale: 1.2;
}

.nav-bar {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 10px;
  overflow: visible;
}

.selected-category {
  color: fuchsia;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  
  .nav-bar {
    margin: 5px 0 5px 0;
  }
  
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  
  .nav-bar {
    margin: 7px 0 7px 0;
  }
  
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  
  .nav-bar {
    margin: 7px 0 7px 0;
  }
  
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  
  .nav-bar {
    margin: 7px 0 7px 0;
  }
  
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  
  .nav-bar {
    margin: 10px 0 10px 0;
  }
  
}
