.campaign-creation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.campaign-form {
  max-width: 450px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #2a2a2a;
  margin: 15px 0;
}

.campaign-form header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.campaign-form h4,
.campaign-preview h4 {
  margin: 0 0 10px 0;
}

.campaign-form button,
.campaign-preview button {
  padding: 10px;
  border: 1px solid #2a2a2a;
  cursor: pointer;
  color: white;
  height: 100%;
  width: 20%;
  border-radius: 5px;
  font-weight: 600;
}

.campaign-form .buttons,
.campaign-preview .buttons {
  display: flex;
  flex-direction: row-reverse;
}

.campaign-form .preview-campaign-button:hover {
  background-color: #3a3a3a;
}

.campaign-form .create-campaign-button:hover,
.campaign-preview .create-campaign-button:hover {
  background-color: #176bb8;
}

.campaign-form .create-campaign-button,
.campaign-preview .create-campaign-button {
  background-color: #135498;
}

.campaign-form .preview-campaign-button {
  background-color: #2a2a2a;
}

.campaign-preview {
  max-width: 550px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #2a2a2a;
  margin: 15px 0;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .campaign-creation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}