.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 1024 / 320;
  overflow: hidden;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.carousel-img {
  width: 100%;
  display: block;
  cursor: pointer;
}

.carousel-footnote {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-weight: 550;
}

.carousel-footnote-title {
  margin-left: 5px;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.next-arrow,
.prev-arrow {
  position: absolute;
  font-size: xx-large;
  opacity: 0.5;
  cursor: pointer;
}

.next-arrow:hover,
.prev-arrow:hover {
  opacity: 1;
}

.transition-indicator-container {
  position: absolute;
  display: flex;
  gap: 35px;
  align-self: center;
  bottom: 0;
}

.transition-indicator-container button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: white;
}

.transition-indicator {
  opacity: 0.5;
  font-size: large;
  font-weight: bold;
}

.transition-indicator:hover {
  cursor: pointer;
  opacity: 1;
}

.transition-indicator.active {
  opacity: 1;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .carousel-footnote {
    font-size: x-small;
  }
  .transition-indicator {
    font-size: small;
  }
  .transition-indicator-container {
    gap: 5px;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .carousel-footnote {
    font-size: x-small;
  }
  .transition-indicator {
    font-size: small;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .carousel-footnote {
    font-size: small;
  }
  .transition-indicator {
    font-size: medium;
  }
  .transition-indicator-container {
    gap: 5px;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .carousel-footnote {
    font-size: small;
  }
  .transition-indicator {
    font-size: medium;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .transition-indicator { 
    font-size: x-large;
  }
}
