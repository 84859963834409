.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-search {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 350px;
  cursor: pointer;
  align-self: center;
}

a {
  color: white;
  text-decoration: none;
}

.user-link:hover {
  color: fuchsia;
}

.user-link span {
  font-weight: 600;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .login-search span {
    font-size: xx-small;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  
}

/* Desktops */
@media only screen and (min-width: 1285px) {

}

