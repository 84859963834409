.highlights-img {
  width: 100%;
  cursor: pointer;
}

.highlights-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.highlights-text {
  width: 26%;
  background: white;
}

.highlights-header {
  font-weight: 600;
}

/* Portrait Smartphones */
@media only screen and (max-width: 600px) {
  .highlights-container {
    padding-top: 5px;
  }
  .highlights-header {
    font-size: xx-small;
  }
}

/* Landscape Smartphones */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: landscape) {
  .highlights-container {
    padding-top: 5px;
  }
  .highlights-header {
    font-size: xx-small;
  }
}

/* Portrait Tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) and (orientation: portrait) {
  .highlights-container {
    padding-top: 10px;
  }
  .highlights-header {
    font-size: smaller;
  }
}

/* Landscape Tablets */
@media only screen and (min-width: 901px) and (max-width: 1284px) and (orientation: landscape) {
  .highlights-container {
    padding-top: 10px;
  }
  .highlights-header {
    font-size: smaller;
  }
}

/* Desktops */
@media only screen and (min-width: 1285px) {
  .highlights-container {
    padding-top: 15px;
  }
}
