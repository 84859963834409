.video-input {
  flex-direction: column;
  width: 100%;
  height: 100px;
  display: flex;
  background-color: #212121;
  border: 1px solid #2a2a2a;
  border-radius: 5px;
  margin-bottom: 15px;
  color: #3a3a3a;
}

.video-input:hover {
  background-color: #2a2a2a;
}

.video-input input[type="file"] {
  display: none;
}

.video-input.dragging {
  background-color: #2a2a2a;
  border-color: #444;
}

.video-input.has-file {
  border-color: #fff;
}

.video-input label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-weight: 550;
  word-break: break-all;
}

.remove-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.remove-file-icon {
  cursor: pointer;
}

.remove-file-icon:hover {
  color: #fff;
}
